import axios from 'axios';
import { getApiBaseUrl } from '../utils/env';

const fetchVideoLibrarySuccess = payload => ({
  type: 'FETCH_TRENDS_AND_VIDEOS_SUCCESS',
  payload,
});

const updateBusyIndicator = payload => ({
  type: 'FETCH_VIDEO_LIBRARY_BUSY',
  payload,
});

export const apiTrendsAndVideosProvider =
  (params) =>
    async dispatch => {
      dispatch(updateBusyIndicator(true));
      try {
        const { data } = await axios.get(`${getApiBaseUrl()}/keyword-trends/matches`, {
          params,
        });
        dispatch(fetchVideoLibrarySuccess(data));
        dispatch(updateBusyIndicator(false));
      } catch (e) {
        dispatch(updateBusyIndicator(false));
        console.error(e);
      }
    };

export type TrendVideo = {
  video_uuid: string,
  media_id: string,
  thumbnail: string,
  org_id: number,
  rightsholder: string,
  title_translated: string,
  score: number,
  format: string,
  publish_date: number
}

type TrendAndVideoItem = {
  keyword_index: number,
  trend: string,
  search_volume: number,
  started: string,
  ended: string,
  headlines: string[],
  urls: string[],
  videos: TrendVideo[]
}

export interface TrendAndVideoState {
  trends: TrendAndVideoItem[];
  format: string;
  page: number;
  geo: string;
  category: number;
  buildId: string | null;
  buildTime: string | null;
  busy: boolean
}

const initialState: TrendAndVideoState = {
  trends: [],
  format: 'vertical',
  geo: 'GB',
  category: 17,
  page: 1,
  buildId: null,
  buildTime: null,
  busy: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_TRENDS_AND_VIDEOS_SUCCESS':
      return {
        ...state,
        trends: action.payload.trends,
        buildId: action.payload.buildId,
        buildTime: action.payload.buildTime,
        page: action.payload.page
      };
    case 'FETCH_VIDEO_LIBRARY_BUSY':
      return {
        ...state,
        busy: action.payload
      };
    case 'UPDATE_FILTER':
      return {
        ...state,
        [action.payload.key]: action.payload.value
      };

    default:
      return state;
  }
};

export default reducer;
