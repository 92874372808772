import { Grid } from '@mui/material';
import VideoBox from './VideoBox';
import { TranscriptPanel } from './TranscriptPanel';
import * as React from 'react';
import { VideoProps, } from '../../types/videos';
import { BoldTypographyLarge } from '../../common/TextLabel';
import { capitalise } from '../../../utils/common';

type VideoPanelProps = {
  video: VideoProps & { matched_score?: number }
}

const HeaderWithScore = ({ rightsholder, score }) => {
  return <Grid container justifyContent={'space-between'}>
    <Grid item title={'Video rightsholder'}><BoldTypographyLarge>{capitalise(rightsholder)}</BoldTypographyLarge></Grid>
    { score && <Grid title={"Matching score"} item>{score}%</Grid>}
  </Grid>
}

export const PanelVideo = ({ video }: VideoPanelProps) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <Grid container spacing={1}>
          <Grid item xs={12} justifyContent={'center'}>
            <VideoBox video={{
              media_id: video.media_id,
              rightsholder: video.rightsholder,
              video_type: video.video_type,
              format: video.format,
              title: video.title,
              publish_date: video.publish_date,
              preview_uri: video.preview_uri,
            }} header={<HeaderWithScore
              rightsholder={video.rightsholder}
              score={video.matched_score}
            />}/>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6} sx={{ display: 'flex' }}>
        <TranscriptPanel
          mediaId={video.media_id}
          rightsholder={video.rightsholder}
          setPlayerKey={() => {}}
        />
      </Grid>
    </Grid>
  );
};


