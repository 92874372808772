import { Dialog, Tab } from '@mui/material';
import * as React from 'react';
import { TabList } from '@mui/lab';
import { useContext, useEffect, useState } from 'react';
import TabContext from '@mui/lab/TabContext';
import { VideoProps } from '../types/videos';
import { PanelVideo } from './video/PanelVideo';
import { PanelVideoSummary } from './video/PanelVideoSummary';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import { apiVideoVariantProvider, VideoLibState } from '../../store/videoLibrarySlice';
import { OrganisationContext } from '../../App';
import { RootState } from '../../index';
import { PanelVideoVariation } from './video/PanelVideoVariation';
import { DialogRoot } from './DialogRoot';

export const VideoDetailedPreview = ({
  open,
  handleClose,
  updateVideoCb,
  video,
}: {
  open: boolean,
  handleClose: () => void,
  updateVideoCb: () => void,
  video: VideoProps;
}) => {
  const dispatch = useDispatch();
  const { selectedOrganisation } = useContext(OrganisationContext);
  const { videoVariation } = useSelector<RootState, VideoLibState>((state) => state.videoLibrary);
  const [tabValue, setTabValue] = useState('1');

  const variantParentId = video.parent_media_id || (video.has_variant && video.media_id);
  useEffect(() => {
    if (!variantParentId) return ;
    dispatch(apiVideoVariantProvider({
      publisher: selectedOrganisation,
      parentMediaId: variantParentId
    }))

    return () => {
      dispatch({
        type: 'RESET_VIDEO_VARIATION'
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'lg'}>
      <DialogRoot handleClose={handleClose}>
        <TabContext value={tabValue}>
            <TabList onChange={(_, newValue) => setTabValue(newValue)}
                     sx={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}
            >
              <Tab value="1" label="Video"/>
              <Tab value="2" label="Summary"/>
              { video.can_manage_video && (Boolean(video.parent_media_id) || video.has_variant) && <Tab value="3" label="Variations"/>}
              { Boolean(video.parent_media_id) && <Tab value="4" label="Original"/>}
            </TabList>
          </TabContext>
          <Box sx={{
            minHeight: '660px',
            display: 'flex'
          }}>
          {tabValue === '1' && <PanelVideo video={video}/>}
          {tabValue === '2' && <PanelVideoSummary summary={video.summary}/>}
          {tabValue === '3' && <PanelVideoVariation
            updateVideoCb={updateVideoCb}
            currentSelectedVideo={video.media_id}
            parentMediaId={video.parent_media_id}
            variations={videoVariation}
            showSelector={video.format === 'vertical'}
          /> }
          {tabValue === '4' && <PanelVideo video={{
              ...video,
              format: 'horizontal',
              title: video.parent_title,
              media_id: video.parent_media_id as string
            }}/>}
          </Box>
      </DialogRoot>
    </Dialog>
  );
};
