import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, CircularProgress, Grid } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import ToolBarPlaylist from './ToolBarPlaylist';
import {
  apiFormOptionsProvider,
  apiGetPlaylists,
} from '../store/playlistSlice';
import { styled } from '@mui/material/styles';
import { Heading } from './common/Heading';
import { EditPlaylistDialog } from './modal/EditPlaylistDialog';
import axios from 'axios';
import { getApiBaseUrl } from '../utils/env';
import { PlaylistTable } from './playlist-detail/PlaylistTable';
import { PlaylistAPIData, PlaylistFormData } from './types/playlist';
import { OrganisationContext } from '../App';

const TableRoot = styled(Grid)`
  margin-top: 80px;
  flex-basis: 100%;
`;

const PlaylistHeading = styled(Heading)`
  margin-bottom: 20px;
`;

export default function Playlist() {
  const dispatch = useDispatch();
  const { selectedOrganisation, organisations } = useContext(OrganisationContext);
  const selectedOrg = organisations.find(({ domain }) => domain === selectedOrganisation);
  const newPlaylistData = {
    format: 'vertical',
    refreshRateInMins: 60,
    orgId: selectedOrg?.id || null,
    type: 1,
    isActive: true,
    name: null,
    uuid: null,
    mrssUsername: null,
    mrssPassword: null,
    mrssUrl: null,
    iabCategories: [],
    iabCategoriesExclude: false,
    trendCategories: [],
    playlistPublisher: [],
    playlistPublisherExclude: false,
    geoCountries: [],
    geoCountriesExclude: false,
    dailyNotificationAtHour: 0,
    emailAddress: [],
    enableNotification: false,
  };
  const { playlists, playlistTypes, iabCategories, playlistPublishers, geoCountries, trendCategories } = useSelector((state: any) => state.playlist);
  const [openPlaylistDialog, setOpenPlaylistDialog] = React.useState<boolean>(false);
  const [playlistData, setPlaylistData] = useState<PlaylistFormData>(newPlaylistData);

  useEffect(() => {
    if (!selectedOrganisation) return;
    dispatch<any>(
      apiFormOptionsProvider()
    );
    dispatch(apiGetPlaylists({
      publisher: selectedOrganisation,
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganisation]);

  const savePlaylist = async (data: PlaylistFormData) => {
    const action = data.uuid ? 'patch' : 'post';
    await axios[action](`${getApiBaseUrl()}/playlist/${selectedOrganisation}`, {
        uuid: data.uuid,
        name: data.name,
        org_id: selectedOrg?.id,
        playlist_type_id: data.type,
        format: data.format,
        refresh_rate_in_mins: data.refreshRateInMins,
        is_active: data.isActive,
        mrss_url: data.mrssUrl,
        mrss_username: data.mrssUsername,
        mrss_password: data.mrssPassword,
        iab_categories: data.iabCategories,
        iab_categories_exclude: data.iabCategoriesExclude,
        trend_categories: data.trendCategories,
        playlist_publisher: data.playlistPublisher,
        playlist_publisher_exclude: data.playlistPublisherExclude,
        playlist_geo_country: data.geoCountries,
        playlist_geo_country_exclude: data.geoCountriesExclude,
        enabled_email_notification: data.enableNotification,
        notification_email_target: data.emailAddress || null,
        notification_daily_at_hour: data.dailyNotificationAtHour
      });
    setOpenPlaylistDialog(false);
    dispatch(apiGetPlaylists({
      publisher: selectedOrganisation,
    }));
  };

  const removePlaylist = async (uuid: string) => {
     await axios.delete(`${getApiBaseUrl()}/playlist/${selectedOrganisation}/${uuid}`);
     dispatch(apiGetPlaylists({
      publisher: selectedOrganisation,
    }));
  }
  return playlists ? (
    <React.Fragment>
      <ToolBarPlaylist
        currentPlaylist={null}
      />
      <TableRoot>
        <Grid container justifyContent={'space-between'}>
          <Grid item>
            <PlaylistHeading>Playlists</PlaylistHeading>
          </Grid>
          <Grid item>
            <Button
              variant={'contained'}
              onClick={() => {
                setPlaylistData(newPlaylistData);
                setOpenPlaylistDialog(true);
              }}
            >
              Add new
            </Button>
          </Grid>
        </Grid>
      </TableRoot>
      {playlists.length > 0 && (
        <PlaylistTable
          onEditPlaylist={(data: PlaylistAPIData) => {
            setPlaylistData({
              format: data.format,
              type: data.playlist_type_id,
              isActive: data.is_active,
              name: data.name,
              mrssPassword: data.mrss_password,
              mrssUrl: data.mrss_url,
              mrssUsername: data.mrss_username,
              orgId: data.org_id,
              refreshRateInMins: data.refresh_rate_in_mins,
              uuid: data.uuid,
              iabCategories: data.iab_categories || [],
              iabCategoriesExclude: data.iab_categories_exclude,
              trendCategories: data.keyword_trend_category_id || [],
              playlistPublisher: (data.playlist_publisher || []).map(String),
              playlistPublisherExclude: data.playlist_publisher_exclude,
              geoCountries: (data.playlist_geo_country || []),
              geoCountriesExclude: (data.playlist_geo_country_exclude || false),
              emailAddress: data.notification_email_target || [],
              dailyNotificationAtHour: data.notification_daily_at_hour || 0,
              enableNotification: data.enabled_email_notification
            });
            setOpenPlaylistDialog(true);
          }}
          onRemovePlaylist={async (data: PlaylistAPIData) => {
            if (!window.confirm(`Are you sure you want to remove this '${data.name}' playlist?`)) {
              return;
            }
            await removePlaylist(data.uuid as string);
          }}
          playlists={playlists}
        />
      )}
      {openPlaylistDialog && (
        <EditPlaylistDialog
          playlistTypes={playlistTypes}
          trendCategories={trendCategories}
          data={playlistData}
          handleClose={() => setOpenPlaylistDialog(false)}
          open={Boolean(openPlaylistDialog)}
          onSave={savePlaylist}
          iabCategories={iabCategories}
          playlistPublishers={playlistPublishers}
          geoCountries={geoCountries}
        />
      )}
    </React.Fragment>
  ) : (
    <CircularProgress style={{ padding: '5px' }} />
  );
}
