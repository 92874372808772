import MenuItem from './MenuItem';

import { ReactComponent as PerformanceIcon } from '../../icons/performance.svg';
import { ReactComponent as EngageIcon } from '../../icons/ico-image_swap.svg';
import { ReactComponent as SecondPlayIcon } from '../../icons/second_play.svg';
import { ReactComponent as SearchIcon } from '../../icons/magnifyglass.svg';
import { ReactComponent as PlaylistIcon } from '../../icons/playlist.svg';
import { ReactComponent as PageIcon } from '../../icons/page.svg';

export default function Menu({ open }) {
  return (
    <>
      <MenuItem open={open} path="/dashboard" end label="Dashboard">
        <PerformanceIcon />
      </MenuItem>
      <MenuItem open={open} path="/dashboard/video-swap" label="Fast Match">
        <EngageIcon />
      </MenuItem>
      <MenuItem open={open} path="/dashboard/promoted-content/1" label="Promoted Content">
        <SecondPlayIcon />
      </MenuItem>
      <MenuItem open={open} path="/dashboard/headline-search" label="Headline Search">
        <SearchIcon />
      </MenuItem>
      <MenuItem open={open} path="/dashboard/video-library" label="Video Library">
        <PlaylistIcon />
      </MenuItem>
      <MenuItem open={open} path="/dashboard/trends-and-videos" label="Trends & Videos">
        <PlaylistIcon />
      </MenuItem>
      <MenuItem open={open} path={`/dashboard/playlist`} label="Playlist">
        <PlaylistIcon />
      </MenuItem>
      {/*<MenuItem open={open} path="/dashboard/gap-analysis" label="Gap Analysis">*/}
      {/*  <InsightsIcon />*/}
      {/*</MenuItem>*/}
      <MenuItem open={open} path="/dashboard/page-view" label="Page Views">
        <PageIcon height={24} />
      </MenuItem>
    </>
  );
}

export function DefaultSection() {
  return '/dashboard';
}
