import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, LinearProgress } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import {
  apiGetPlaylistTrendingArticle,
  PlaylistState
} from '../../store/playlistSlice';
import { OrganisationContext } from '../../App';
import { useParams } from 'react-router-dom';
import { RootState } from '../../index';
import { PlaylistTrendingArticleList } from './PlaylistTrendingArticleList';
import PlaylistRoot from './PlaylistRoot';
import { PlaylistTrendingItem } from '../types/playlist';
import { VideoDetailedWithMF } from '../modal/VideoDetailedWithFM';


export default function PlaylistTrendingArticle() {
  const { playlist } = useParams();
  const dispatch = useDispatch();
  const { selectedOrganisation } = useContext(OrganisationContext);
  const {
    trendingArticles,
    busy,
  } = useSelector<RootState, PlaylistState>((state) => state.playlist);

  const [videoPreview, setVideoPreview] = useState<PlaylistTrendingItem | null>();
  useEffect(() => {
    if (!selectedOrganisation) return
    dispatch(apiGetPlaylistTrendingArticle({ publisher: selectedOrganisation, playlistId: playlist }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrganisation]);

  return trendingArticles ? (
    <PlaylistRoot>
      {trendingArticles && (
        <Box sx={{ position: 'relative' }}>
          {busy && <LinearProgress sx={{ position: 'absolute', top: 0, width: '100%' }}
                                   color="success"/>}
          <PlaylistTrendingArticleList setVideoPreview={setVideoPreview} articles={trendingArticles} />
        </Box>
      )}
      {busy && <LinearProgress color="success"/>}
      {videoPreview && <VideoDetailedWithMF
        open={Boolean(videoPreview)}
        video={{
          video_type: videoPreview.video_type,
          media_id: videoPreview.video_id,
          parent_media_id: videoPreview.parent_media_id,
          publish_date: videoPreview.video_publish_start_date ? new Date(videoPreview.video_publish_start_date * 1000) : null,
          rightsholder: videoPreview.video_rightsholder,
          format: videoPreview.format,
          title: videoPreview.video_title,
          parent_title: videoPreview.parent_title,
          parent_title_translated: videoPreview.parent_title_translated,
          summary: videoPreview.video_summary,
          has_variant: false,
          can_manage_video: false,
          article_thumbnail: videoPreview.article_thumbnail,
          article_title: videoPreview.article_title
        }}
        handleClose={() => setVideoPreview(null)}
      />}
    </PlaylistRoot>
  ) : (
    <CircularProgress style={{ padding: '5px' }}/>
  );
}
