import {
  ButtonGroup,
  MenuItem,
  FormControl,
  Grid,
  Select,
  SelectChangeEvent,
  InputLabel
} from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';
import { Heading } from './common/Heading';
import { useDispatch, useSelector } from 'react-redux';
import * as React from 'react';
import { RootState } from '../index';
import { TrendAndVideoState } from '../store/trendsAndVideosSlice';

type SelectOptionsProps = {
  onChange: (event: SelectChangeEvent) => void;
  options: Record<string, string>;
  defaultValue: string;
  label: string;
}

const SelectOptions = ({ onChange, defaultValue, options, label }: SelectOptionsProps) => {
  return <Grid item>
    <ButtonGroup>
      <FormControl
        variant="outlined"
        margin="dense"
        style={{
          marginTop: '0px',
          marginBottom: '0px',
        }}
      >
        <InputLabel>{label}</InputLabel>
        <Select
          defaultValue={defaultValue}
          onChange={onChange}
          label={label}
          sx={{
            fontFamily: 'ProximaNovaBold',
            textTransform: 'none',
            color: '#000',
            height: '43px',
            width: '200px',
            marginRight: '0',
          }}
        >
          {Object.entries(options).map(([key, label]) => (
            <MenuItem key={key} value={key}>{label}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </ButtonGroup>
  </Grid>;
};

function ToolBarVideoTrends() {
  const dispatch = useDispatch();
  const {
    format, geo, category,
  } = useSelector<RootState, TrendAndVideoState>((state) => state.trendsAndVideos);

  return (
    <Toolbar style={{ paddingBottom: '45px', paddingLeft: '15px' }}>
      <Grid container alignItems={'center'} gap={'8px'}>
        <Grid item sx={{ mr: 2 }}>
          <Heading>Videos and trends</Heading>
        </Grid>

        <SelectOptions
          defaultValue={format}
          onChange={(e) => {
            dispatch({
              type: 'UPDATE_FILTER', payload: {
                key: 'format',
                value: e.target.value
              }
            });
          }}
          label="Format"
          options={{
            vertical: 'vertical',
            horizontal: 'horizontal',
          }}
        />

        <SelectOptions
          label="Geo"
          defaultValue={geo}
          onChange={(e) => {
            dispatch({
              type: 'UPDATE_FILTER', payload: {
                key: 'geo',
                value: e.target.value
              }
            });
          }}
          options={{
            GB: 'United Kingdom',
            US: 'United States',
          }}
        />

        <SelectOptions
          label="Category"
          defaultValue={category.toString()}
          onChange={(e) => {
            dispatch({
              type: 'UPDATE_FILTER', payload: {
                key: 'category',
                value: e.target.value
              }
            });
          }}
          options={{
            '17': 'Sports',
          }}
        />

      </Grid>
    </Toolbar>
  );
}

export default styled(ToolBarVideoTrends)`
    margin-bottom: 40px;
`;
