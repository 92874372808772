import ReactPlayer from 'react-player';
import Box from '@mui/material/Box';
import { VideoPlayerProps } from '../../types/videos';

export const MuxVideoPlayer = ({ video }: { video: VideoPlayerProps }) => {
  return (
    <Box sx={{
      aspectRatio: video.format === 'horizontal' ? '16 / 9' : '9 / 16',
      margin: '0 auto',
      backgroundColor: '#ccc'
    }} data-preview-uri={video.preview_uri}>
      <ReactPlayer
        key="mux"
        url={video.media_id ? `https://stream.mux.com/${video.media_id}.m3u8` : video.preview_uri}
        controls
        width={'100%'}
        height={'100%'}
        style={{ marginLeft: 'auto', marginRight: 'auto' }}
      />
    </Box>
  );
};
