import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, LinearProgress } from '@mui/material';
import PaginationTable from './Pagination';
import { Fragment, useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { apiVideoLibraryProvider, VideoLibState } from '../store/videoLibrarySlice';
import ToolBarVideoLibrary from './ToolBarVideoLibrary';
import { VideoLibraryTable } from './VideoLibraryTable';
import { OrganisationContext } from '../App';
import { VideoDetailedPreview } from './modal/VideoDetailedPreview';
import { EditVideoRequest } from './modal/VideoEditRequest';
import { RootState } from '../index';
import { VideoLibItem } from './types/videos';

export default function VideoLibrary() {
  const dispatch = useDispatch();
  const { selectedOrganisation } = useContext(OrganisationContext);

  const {
    searchText,
    videoLibraryFilter,
    videos,
    pagination,
    busy
  } = useSelector<RootState, VideoLibState>((state) => state.videoLibrary);

  const [videoPreview, setVideoPreview] = useState<VideoLibItem>();
  const [editVideoRequest, setEditVideoRequest] = useState<VideoLibItem>();
  const [videoPreviewIndex, setVideoPreviewIndex] = useState<number | undefined>();
  const [searchInputText, setSearchInputText] = useState({
    searchText: '',
  });
  const handleClose = () => setVideoPreviewIndex(undefined);

  const updateList = (search: string) => {
    return apiVideoLibraryProvider({
      publisher: selectedOrganisation,
      limit: pagination.itemsPerPage,
      page: pagination.currentPage,
      filterByFormat: videoLibraryFilter,
      search,
    })
  }

  useEffect(() => {
    if (videoPreviewIndex !== undefined) {
      setVideoPreview(videos[videoPreviewIndex]);
    } else {
      setVideoPreview(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoPreviewIndex, videos]);

  useEffect(() => {
    if (!selectedOrganisation) return;
    dispatch<any>(updateList(searchText));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, pagination.itemsPerPage, pagination.currentPage, videoLibraryFilter, selectedOrganisation]);

  return videos ? (
    <Fragment>
      {videoPreview && (
        <VideoDetailedPreview
          open={Boolean(videoPreview)}
          handleClose={handleClose}
          updateVideoCb={() => {
            dispatch<any>(updateList(searchText));
            if (videoPreviewIndex !== undefined) {
              setVideoPreview(videos[videoPreviewIndex]);
            }
          }}
          video={{
            media_id: videoPreview.media_id,
            summary: videoPreview.summary,
            rightsholder: videoPreview.rightsholder,
            video_type: videoPreview.video_type,
            format: videoPreview.format,
            preview_uri: videoPreview.preview_uri,
            title: videoPreview.title,
            publish_date: new Date(videoPreview.publish_date),
            parent_media_id: videoPreview.parent_media_id,
            parent_title: videoPreview.parent_title,
            parent_title_translated: videoPreview.parent_title_translated,
            has_variant: videoPreview.has_variant,
            can_manage_video: videoPreview.canManageVideo
          }}
        />
      )}
      { editVideoRequest && <EditVideoRequest open={Boolean(editVideoRequest)} handleClose={() => setEditVideoRequest(undefined)} video={editVideoRequest}/> }

      <ToolBarVideoLibrary
        setSearchInputText={setSearchInputText}
        searchInputText={searchInputText}
      />

      <Box sx={{ position: 'relative' }}>
        {busy &&
          <LinearProgress sx={{ position: 'absolute', top: 0, width: '100%' }} color="success"/>}
        <VideoLibraryTable videos={videos} onSetVideoPreview={setVideoPreviewIndex} onEditRequested={setEditVideoRequest}/>
      </Box>
      {busy && <LinearProgress color="success"/>}
      <PaginationTable
        defaultSize={20}
        busy={busy}
        startPage={pagination.currentPage - 1}
        count={pagination.totalItems}
        onChange={page =>
          dispatch({
            type: 'SET_PAGINATION',
            payload: {
              currentPage: page + 1,
            },
          })
        }
        updateRowPerPage={totalPerPage => {
          dispatch({
            type: 'SET_PAGINATION',
            payload: {
              currentPage: 1,
              itemsPerPage: totalPerPage,
            },
          });
        }}
      />
    </Fragment>
  ) : (
    <CircularProgress style={{ padding: '5px' }}/>
  );
}
