export type PlaylistFormData = {
  format: string,
  type: number,
  isActive: boolean,
  name: string | null,
  mrssPassword: string | null,
  mrssUrl: string | null,
  mrssUsername: string | null,
  orgId: number | null,
  refreshRateInMins: number,
  uuid: string | null,
  iabCategories: string[],
  trendCategories: number[],
  iabCategoriesExclude: boolean,
  playlistPublisher: string[],
  playlistPublisherExclude: boolean,
  geoCountries: number[],
  geoCountriesExclude: boolean,
  enableNotification: boolean,
  emailAddress: string[],
  dailyNotificationAtHour: number | null,
}

export type PlaylistAPIData = {
  format: string,
  playlist_type_id: number,
  playlist_type_name?: string,
  is_active: boolean,
  name: string | null,
  mrss_password: string | null,
  mrss_url: string | null,
  mrss_username: string | null,
  org_id: number | null,
  refresh_rate_in_mins: number,
  last_refreshed: string,
  uuid: string | null,
  iab_categories: string[],
  iab_categories_exclude: boolean,
  keyword_trend_category_id: number[],
  playlist_publisher: number[],
  playlist_publisher_exclude: boolean,
  playlist_geo_country: number[],
  playlist_geo_country_exclude: boolean
  play_count: number
  video_count: number
  playlist_geo_country_name: string[]
  iab_category_names: string[]
  playlist_publisher_name: string[],
  organisation: string
  notification_email_target: string[]
  enabled_email_notification: boolean;
  notification_daily_at_hour: number;
}

export type IABCategory = {
  id: string;
  name: string;
  parent: string;
  level: number;
}

export type TrendCategory = {
  id: number;
  name: string;
}


export type PlaylistPublisher = {
  id: number;
  name: string;
}

export interface PlaylistVideo {
  id: number;
  video_thumbnail: string;
  video_publish_date: Date;
  video_title: string;
  video_title_translated: string;
  video_rightsholder: string;
  video_format: string;
  video_summary: string;
  is_manually_added: boolean;
  video_duration: number;
  article_title: string;
  video_id: string;
  video_producer: string;
  video_type: string;
  article_thumbnail: string;
  article_thumb: string;
  article_publish_date: Date;
  record_inserted_at: Date;
  has_trending_matched: boolean;
  video_play_count: number;
  lang: string;
  pos: number;
  total_page_views: number;
  transcript?: string;
  is_opus: boolean;
  parent_media_id: string;
  parent_title: string;
  parent_title_translated: string;
  can_manage_video: boolean;
}

export interface PlaylistCampaignDeliveryArticle {
  count: number;
  page_url: string
}

export interface PlaylistTrendingItem {
  video_thumbnail: string;
  video_title: string;
  video_rightsholder: string;
  article_thumb: string;
  record_inserted_at: number;
  event_count: number;
  headline_translated: string;
  article_id: string;
  has_video_matched: boolean;
  video_id: string;
  article_thumbnail: string;
  article_title: string;
  video_type: string;
  parent_media_id: string;
  parent_title: string;
  parent_title_translated: string;
  video_publish_start_date: number;
  format: string;
  article_publish_date: number;
  matched_score_percentage: number;
  is_trending: boolean;
  web_url: string;
  organisation_origin: string;
  horizontal_matched: Record<string, any>;
  opus_clip_id: string;
  video_summary: string;
}

export interface Player {
  name: string;
  id: number;
  ad_unit: string;
  playlist_uuid: string;
  player: string;
}

export enum PLAYLIST_TYPES {
  DEEP_MATCH = 1,
  MANUAL = 2,
  MRSS = 3,
  TREND_MATCH = 4,
}