import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, Grid, IconButton, LinearProgress, Typography } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { TrendAndVideoState, TrendVideo } from '../store/trendsAndVideosSlice';
import { RootState } from '../index';
import { apiTrendsAndVideosProvider } from '../store/trendsAndVideosSlice';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import { TableCellBody, TableCellHead, TableRow } from './common/Table';
import TableBody from '@mui/material/TableBody';
import { VideoThumbnail } from './common/VideoThumbnail';
import * as React from 'react';
import { VideoTrendPreview } from './modal/VideoTrendPreview';
import ToolBarVideoTrends from './ToolBarVideoTrends';
import styled from '@emotion/styled';
import Moment from 'react-moment';
import { ReactComponent as GainingIcon } from '../icons/gaining-article.svg';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Box from '@mui/material/Box';
import { Link } from './common/Link';

const PixelLink = styled(Link)`
  color: ${props => props.theme.palette.primary.main};
  &:active, &:hover {
    color: #21316C;
      text-decoration: underline;
  }
`

const VideoLength = styled.span`
    position: absolute;
    right: -6px;
    top: -6px;
    background: red;
    z-index: 34;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    color: white;
    text-align: center;
    font-size: 12px;
    display: flex;
    font-weight: bold;
    align-items: center;
    justify-content: center;
`;

export default function TrendsAndVideos() {
  const dispatch = useDispatch();
  const [videoPreview, setVideoPreview] = useState<{
    currentTrend: string,
    videos: TrendVideo[]
  }>();
  const {
    trends, format, geo, category, page, buildTime, busy
  } = useSelector<RootState, TrendAndVideoState>((state) => state.trendsAndVideos);

  useEffect(() => {
    dispatch(apiTrendsAndVideosProvider({
      geo,
      category,
      format,
      page
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [format, geo, category, page]);

  return trends ? (
    <Fragment>
      <ToolBarVideoTrends/>
      <Grid container justifyContent={'flex-end'} alignItems={'center'} gap={1}>
        <Grid item>
          <IconButton disabled={page >= 6} onClick={() => dispatch({
            type: 'UPDATE_FILTER',
            payload: { key: 'page', value: page + 1 }
          })}>
            <ArrowBackIosNewIcon/>
          </IconButton>
        </Grid>
        <Grid item>
          {buildTime && <span title={new Date(buildTime).toLocaleString()}>Build time: <Moment
            fromNow>{buildTime}</Moment></span>}
        </Grid>
        <Grid item>
          <IconButton disabled={page === 1} onClick={() => dispatch({
            type: 'UPDATE_FILTER',
            payload: { key: 'page', value: page - 1 }
          })}>
            <ArrowForwardIosIcon/>
          </IconButton>
        </Grid>
      </Grid>
      <Box sx={{ position: 'relative' }}>
        {busy &&
          <LinearProgress sx={{ position: 'absolute', top: 0, width: '100%' }} color="success"/>}
      </Box>
      <Table>
        <TableHead>
          <TableRow>
            <TableCellHead>#</TableCellHead>
            <TableCellHead></TableCellHead>
            <TableCellHead>Trend</TableCellHead>
            <TableCellHead>Headlines</TableCellHead>
            <TableCellHead>Search volume</TableCellHead>
            <TableCellHead>Started</TableCellHead>
            <TableCellHead>Video</TableCellHead>
            <TableCellHead>Rightsholder</TableCellHead>
          </TableRow>
        </TableHead>
        <TableBody>
          {trends.map(({
            keyword_index: index,
            trend,
            search_volume,
            started,
            videos,
            headlines,
            ended,
            urls
          }) =>
            (<TableRow key={index}>
              <TableCellBody>{index}</TableCellBody>
              <TableCellBody>{ended === null &&
                <GainingIcon style={{ display: 'flex' }}/>}</TableCellBody>
              <TableCellBody style={{ whiteSpace: 'nowrap' }}>{trend}</TableCellBody>
              <TableCellBody>{headlines.map((headline, index) => {
                  const url = urls[index];
                  const hostname = url ? new URL(urls[index]).hostname : '';
                  return (<PixelLink key={headline} target={'_blank'} href={url || '#'} title={headline + (hostname ? `\n(${hostname})` : '')}><Typography
                    sx={{
                      width: {
                        xs: '200px',
                        lg: '400px',
                      }
                    }}
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }} fontSize={14}>{headline}{hostname ? ` (${hostname})` : ''}</Typography></PixelLink>);
                }
              )}</TableCellBody>
              <TableCellBody>{search_volume.toLocaleString()}</TableCellBody>
              <TableCellBody>
                <Grid container alignItems={'center'} gap={1}>
                  <Grid item>
                    <Moment  style={{ whiteSpace: 'nowrap' }} fromNow>{started}</Moment>
                  </Grid>
                </Grid>
              </TableCellBody>
              <TableCellBody>{videos.length > 0 ? <div style={{
                position: 'relative',
                width: videos[0].format === 'horizontal' ? '120px' : '60px'
              }} onClick={() => setVideoPreview({
                currentTrend: trend,
                videos
              })}>
                <VideoThumbnail imageUrl={videos[0].thumbnail} format={videos[0].format}/>
                <VideoLength>{videos.length}</VideoLength>
              </div> : ''}</TableCellBody>
              <TableCellBody>{videos.length > 0 ? videos[0].rightsholder : ''}</TableCellBody>
            </TableRow>))}
        </TableBody>
      </Table>
      {videoPreview && (
        <VideoTrendPreview
          open={Boolean(videoPreview)}
          handleClose={() => setVideoPreview(undefined)}
          trend={videoPreview.currentTrend}
          videos={videoPreview.videos}
        />
      )}
    </Fragment>
  ) : (
    <CircularProgress style={{ padding: '5px' }}/>
  );
}
