import { Dialog, DialogTitle, Grid } from '@mui/material';
import * as React from 'react';
import { DialogRoot } from './DialogRoot';
import { TrendVideo } from '../../store/trendsAndVideosSlice';
import ProducerHeading from '../ProducerHeading';
import Moment from 'react-moment';
import { MuxVideoPlayer } from './video/MuxVideoPlayer';
import { styled } from '@mui/material/styles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIos from '@mui/icons-material/ArrowForwardIos';
import Box from '@mui/material/Box';

const VideoRoot = ({ video }: { video: TrendVideo }) => (
  <>
    <MuxVideoPlayer video={{
      format: video.format,
      media_id: video.media_id,
      rightsholder: video.rightsholder,
      title: video.title_translated,
      video_type: 'pixels',
      publish_date: new Date(video.publish_date * 1000)
    }}/>
  </>
);

const Video = ({ video, currentIndex, videoLength }: { video: TrendVideo, currentIndex: number, videoLength: number}) => {
  return (
    <>
      <Grid key={video.media_id} sx={{ display: 'flex', mb: 4 }} container spacing={1}>
        <Grid item xs={9}>
          <Grid container alignItems={'center'}>
            {video.title_translated}
          </Grid>
        </Grid>
        <Grid item xs={3} textAlign={'right'}><ProducerHeading row={video}/></Grid>
        <Grid item xs={12}>
           <Box data-one sx={{
              maxWidth: video.format === 'horizontal' ? '80%' : '30%',
             margin: '0 auto'
            }}>
            <VideoRoot video={video}/>
           </Box>
        </Grid>
        <Grid item xs={4}>
          <Moment format="Do MMM YYYY">{video.publish_date * 1000}</Moment>
        </Grid>
        <Grid item xs={4} textAlign={'center'}>
          ({currentIndex+1} / {videoLength})
        </Grid>
        <Grid item xs={4} textAlign={'right'}>
          Trend match: {Math.floor(video.score * 100)}%
        </Grid>
      </Grid>
    </>
  );
};

const TitleDialog = styled(DialogTitle)`
  position: relative;
  padding-top: 16px;

  &:after {
    content: ' ';
    background-color: #ccc;
    width: calc(100% + 40px);
    height: 1px;
    position: absolute;
    left: -40px;
    bottom: 10px;
  }
`;

const Title = styled('div')`
  font-weight: bold;
  color: black;
`;

export const VideoTrendPreview = ({
  open,
  handleClose,
  videos,
  trend,
}: {
  open: boolean,
  handleClose: () => void,
  videos: TrendVideo[],
  trend: string
}) => {
  const [index, setIndex] = React.useState(0);
  return (
    <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={'lg'}>
      <DialogRoot handleClose={handleClose}>
        <TitleDialog><Title>{trend}</Title></TitleDialog>
        <Grid container sx={{ marginTop: 2}}>
          <Grid item xs={2}>
            <Grid container justifyContent={'center'} alignItems={'center'} height={'100%'}>
              <ArrowBackIosIcon
                color={videos.length > 1 && index !== 0 ? 'primary' : 'disabled'}
                fontSize={'large'}
                onClick={() => {
                if (index > 0) {
                  setIndex(index - 1);
                }
              }}
              />
            </Grid>
          </Grid>
          <Grid item xs={8}>
            <Video video={videos[index]} currentIndex={index} videoLength={videos.length}/>
          </Grid>
          <Grid item xs={2}>
            <Grid container justifyContent={'center'} alignItems={'center'} height={'100%'}>
              <ArrowForwardIos
                color={videos.length > 1 && index < videos.length - 1 ? 'primary' : 'disabled'}
                onClick={() => {
                if (index < videos.length - 1) {
                  setIndex(index + 1);
                }
              }}
                fontSize={'large'} />
            </Grid>
          </Grid>
        </Grid>
      </DialogRoot>
    </Dialog>
  );
};
